import InterventionService from "services/interventionService";
import userModel from "../models/userModel";
import * as moment from 'moment'
import 'moment-timezone';

export default class InterventionManager {

    private interventionService: InterventionService;

    constructor(currentUser: userModel) {
        this.interventionService = new InterventionService(currentUser);

    }

    getContactList(): Promise <any> {
        return this.interventionService.getContactList()
            .then((data: any) => {
                return data;
            })
            .catch((error: any) => console.log(error))
    }
    getContact(id: number): Promise <any> {
        return this.interventionService.getContact(id)
            .then((data: any) => {
                return data;
            })
            .catch((error: any) => console.log(error))
    }
    addContact(data:any): Promise <any>{
        return this.interventionService.addContact(data)
        .then((data: any) => {
            return data;
        })
        .catch((error: any) => console.log(error))
    }
    editContact(id: number, data:any): Promise <any>{
        return this.interventionService.editContact(id, data)
        .then((data: any) => {
            return data;
        })
        .catch((error: any) => console.log(error))
    }
    deleteContact(id:number): Promise <any>{
        return this.interventionService.deleteContact(id)
        .then((data: any) => {
            return data;
        })
        .catch((error: any) => console.log(error))
    }

    getInterventions(filter: any): Promise < any > {
        return this.interventionService.getInterventions(filter)
            .then((data: any) => {
                let i = 0;
                data.forEach(item => {
                    item.id = i++;
                    item.participantId = item.interventions[0].participantId;
                    let temp = item.interventions.filter(intv => intv.interventionTypeId === 10 || intv.interventionTypeId === 11)
                    let non_call_interventions = item.interventions.filter(intv => intv.interventionTypeId !== 10 && intv.interventionTypeId !== 11)
                    let contact_intervention = item.interventions.filter(intv => intv.interventionTypeId === 12 || intv.interventionTypeId === 13)
                    item.interventions = non_call_interventions;
                    if (temp[0]) {
                        item.contactReason = temp[0].followupText;
                        item.contactRequest = true;
                    } else {
                        item.contactReason = "";
                        item.contactRequest = false;
                    }
                    if (contact_intervention.length > 0){
                        item.contact = contact_intervention;
                    }
                    
                    item.notes.forEach(el => {
                        el.createdAt = moment.utc(el.createdAt).local().format();
                    });
                    
                });
                return data;

            })
            .catch((error: any) => console.log(error))

    }
    
    getClients(): Promise < any > {
        return this.interventionService.getClients()
            .then((data: any) => {
                return data;
            })
            .catch((error: any) => console.log(error))

    }
    getInterventionsUsers(): Promise < any > {
        return this.interventionService.getInterventionsUsers()
            .then((data: any) => {
                return data;
            })
            .catch((error: any) => console.log(error))

    }
    getInterventionsCompanys(): Promise < any > {
        return this.interventionService.getInterventionsCompanys()
            .then((data: any) => {
                return data;
            })
            .catch((error: any) => console.log(error))
    }
    getInterventionsStatuses(): Promise < any > {
        return this.interventionService.getInterventionsStatuses()
            .then((data: any) => {
                return data;
            })
            .catch((error: any) => console.log(error))
    }
    updateUser(participantId: string, surveyTypeId: number, surveyDate: string, data: string): Promise < any > {
        return this.interventionService.updateUser(participantId, surveyTypeId, surveyDate, data)
            .then((data: any) => {
                return data;
            })
            .catch((error: any) => console.log(error))

    }
    updateStatus(participantId: string, surveyTypeId: number, surveyDate: string, data: string): Promise < any > {
        return this.interventionService.updateStatus(participantId, surveyTypeId, surveyDate, data)
            .then((data: any) => {
                return data;
            })
            .catch((error: any) => console.log(error))

    }

}