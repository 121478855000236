import userModel from "../models/userModel";
const axios = require('axios');

export default class reportService {

    public getReport(currentUser: userModel, reportId: string) {
        return axios({
            method: 'GET',
            url: process.env.REACT_APP_API_URL + "/api/report/" + reportId + "/embed-link",
            headers: {
                "Content-Type": "application/json-patch+json",
                "accept": "text/plain",
                "Authorization": "bearer " + currentUser.smToken
            }
        })
        .then(function (token: any) {
            return token.data;
        })
        .catch(function (error: object){
            console.log(error);
        });
    }
}
