import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import NoteModel from 'models/note';
import userModel from "../models/userModel";

export default class NoteService {
  private client: AxiosInstance;

  constructor(currentUser: userModel) {
    let config: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'content-type': 'application/json',
        Authorization: 'bearer ' + currentUser.smToken,
      },
    };

    this.client = axios.create(config);
  }

  sendEmail(participantId: string, surveyTypeId: number, surveyDate: string, assignedUserId:number) {
    return this.client
      .post('/api/intervention/' + participantId + '/' + surveyTypeId + '/' + surveyDate + '/notify', '"' + assignedUserId + '"')
      .then(()=>{
        return true;
      })
      .catch((er: any) => console.log(er));
  }

  getNotes(participantId: string, surveyTypeId: number, surveyDate: string) {
    return this.client
      .get<NoteModel[]>('/api/intervention/' + participantId + '/' + surveyTypeId + '/' + surveyDate + '/notes')
      .then(function(definitions: { data: any }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }

  saveNote(participantId: string, surveyTypeId: number, surveyDate: string, data: string) {
    return this.client
      .post('/api/intervention/' + participantId + '/' + surveyTypeId + '/' + surveyDate + '/notes', '"' + data + '"')
      .then(()=>{
        return true;
      })
      .catch((er: any) => console.log(er));
  }
}