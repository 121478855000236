export default class Utils {
  /*
   * Linux Epoch
   *
   * Gets the linux epoch from the given date object
   */
  static linuxEpoch(datetime: Date): number {
    return Math.round(datetime.getTime() / 1000);
  }

  /*
   * Linux Epoch
   *
   * Gets the linux epoch for the current time
   */
  static currentLinuxEpoch(): number {
    return Math.round(Date.now() / 1000);
  }

  /**
   * Parses a query string into a class with all of its variables
   * @param {string} queryString The query string to parse
   */
  static parseQueryString(queryString: string) {
      
    let variables: any = {};

    if(queryString.substring(0, 1) === "#") {
        queryString = queryString.substring(1, queryString.length);
    }

    queryString.split('&').forEach(variableString => {
        let variableSplit: string[] = variableString.split('=');

        variables[variableSplit[0]] = decodeURIComponent(variableSplit[1]);
    });

    return variables;
  }
}
