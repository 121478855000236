import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './components/Login';
import Logout from './components/Logout';
import AzureB2cCallback from 'components/AzureB2C/AzureB2cCallback';
import InvitationAccept from 'components/InvitationAccept';

ReactDOM.render(
  <Router>
    <Switch>
      <Route exact path={'/'} component={Login} />
      <Route exact path={'/login'} component={Login} />
      <Route exact path={'/logout'} component={Logout} />
      <Route exact path={'/invitation/accept/:invitation_id'} component={InvitationAccept} />
      <Route exact path={'/azureb2c/callback'} component={AzureB2cCallback} />
      <Route path="*">
        <App />
      </Route>
    </Switch>
  </Router>
  ,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
