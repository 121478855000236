const axios = require('axios');

export default class UserCapabilitiesService {
    constructor() {
    }

    getUserCapabilities(token: string): Promise<any> {
        return axios({
            method: 'GET',
            url: process.env.REACT_APP_API_URL + "/api/user/capabilities",
            headers: {
                "Content-Type": "application/json-patch+json",
                "accept": "text/plain",
                "Authorization": "bearer " + token
            }
        })
        .then(function (token: any) {
            return token.data;
        })
        .catch(function (error: object){
            console.log(error);
        });
    }

    passwordResetRequest(email: string) {
        return axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + "/api/user/resetpassword",
            headers: {
                "Content-Type": "application/json",
                "accept": "*/*"
            },
            data: "\"" + email + "\""
        })
        .then(function (token: any) {
            return true
        })
        .catch(function (error: object){
            return false;
        });
    }

    resetPassword(key: string, password: string) {
        return axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + "/api/user/createNewPassword",
            headers: {
                "Content-Type": "application/json",
                "accept": "*/*"
            },
            data: {key: key, password: password}
        })
        .then(function (token: any) {
            return true
        })
        .catch(function (error: object){
            return false;
        });
    }

    acceptUserInvitation(key, password) {
        return axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + "/api/user/acceptinvitation",
            headers: {
                "Content-Type": "application/json",
                "accept": "*/*"
            },
            data: {invitationId: key, password: password}
        })
        .then(function (token: any) {
            return true
        })
        .catch(function (error: any){
            return error.response.data;
        });
    }

}