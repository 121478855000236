import userModel from "../models/userModel";
import NoteService from "services/noteService";
import Note from '../models/note'


export default class NoteManager {
    private noteService: NoteService;
    
    constructor(currentUser: userModel) {
        this.noteService =  new NoteService(currentUser);

    }
    sendEmail(participantId: string, surveyTypeId: number, surveyDate: string, assignedUserId:number): Promise<any> {   
        let email = "test"   
        return this.noteService.sendEmail(participantId, surveyTypeId, surveyDate, assignedUserId)
            .then((data: any) => {
                return data;
            })
            .catch((error: any) => console.log(error))

    }
    getNotes(participantId: string, surveyTypeId: number, surveyDate: string): Promise<any> {    
        return this.noteService.getNotes(participantId, surveyTypeId, surveyDate)
            .then((data: any) => {
                return data;
            })
            .catch((error: any) => console.log(error))

    }
    saveNote(participantId: string, surveyTypeId: number, surveyDate: string, data: string): Promise<any> {      
        return this.noteService.saveNote(participantId, surveyTypeId, surveyDate, data)
            .then((data: any) => {
                return data;
            })
            .catch((error: any) => console.log(error))

    }   
}