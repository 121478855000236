import React, {useEffect,useState} from 'react';
import Fade from 'react-bootstrap/Fade'
import PreloadManager from '../managers/preloadManager';
import BarLoader from "react-spinners/BarLoader";
import PreloadDataModel from '../models/preloadDataModel';

const Preloader = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [reportsLoading, setReportsLoading] = useState(false);
    const [currentData, setCurrentData] = useState<PreloadDataModel>(PreloadManager.blankObject());
    const override = `display: block;
        margin: 0 auto;`;

    let preloadManager: PreloadManager = new PreloadManager();

    useEffect(() => {
        let promiseList: any = [];
        setLoading(props.active); 

        setTimeout(()=>{
            preloadManager.getpreloadInfo()
            .then((data) => {
                setCurrentData(data[0]);
                setReportsLoading(true);
                
                let x: number = 0;
                let y: number = 10;

                var myVar = setInterval(()=>{
                    if (y) {
                        y--;
                        setCurrentData(data[x++]);
                        if (x === data.length)
                            x = 0;
                    }
                    else {
                        clearInterval(myVar);
                    }
                }, 4000);
               
            });
        },2000);


    }, []);

    return (
        <div className="preload_div">
            <Fade timeout={4000} in={reportsLoading}>
                <div>
                    <h3 className={"h3-" + currentData.style}>{currentData.title}</h3>
                    <p className={"p-" + currentData.style}><img className={"image-" + currentData.style} src={currentData.image} />
                        {currentData.message}</p>
                    <p className={"p-" +currentData.style}><a target="_blank" href={currentData.url}>{currentData.button_message}</a></p>
                </div>
            </Fade>
            <BarLoader
            color={"#32855a"}
            css={override}
            width={600}
            loading={loading}
            />
            <p>Please wait, while your report is loading</p>
        </div>
    );
}

export default Preloader;
