import React, {useState} from 'react';
import ReportManager from '../../managers/reportManager';
// @ts-ignore
import Report from 'powerbi-report-component';

const ReportComp = (props) => {

    //const [report, setReport] = useState(null);
    let report = null;
    let hasLoaded = false;

    const onEmbedRender = (report) => {
        //console.log("Set Has Loaded to true", hasLoaded);
        hasLoaded = true;
        //console.log("Render!");
    };

    const onLoadAndSetTokenListener = (reportFromCallback : Report) => {
        setTokenExpirationListener(props.config.expiration);
        //setReport(reportFromCallback);
        report = reportFromCallback;

        hasLoaded = false;
        // console.log("Set Has Loaded to false", hasLoaded);
        // console.log("Loading!")
        // console.log(reportFromCallback);

        // setTimeout(() => {
        //     // If we are not loading!
        //     //console.log("Has Loaded: ", hasLoaded);
        //     if(!hasLoaded) {
        //         window.location.reload();
        //     }
        // }, 30000);
    };

    const setTokenExpirationListener = (tokenExpiration) => {

        // time until token refresh in milliseconds
        const currentTime = Date.now();
        const expiration = Date.parse(tokenExpiration);
        //console.log(currentTime, expiration);
        let safetyInterval = (expiration - currentTime) * .25;
        //console.log('expiration', (expiration - currentTime));
        //console.log('safety', safetyInterval);
        let timeout = expiration - currentTime - safetyInterval;
        // if token already expired, generate new token and set the access token
        if (timeout <= 0) {
            updateToken() // set timeout so minutesToRefresh minutes before token expires
        } else {

            //console.log("The token will be refreshed in " + (timeout/1000) + " seconds.");

            setTimeout(() => {
                updateToken();
            }, timeout);
        }

    };


    const updateToken = () => {
        // Generate new EmbedToken (Axios call to get config/ token)
        //console.log("token update");
        // setTokenExpirationListener(createExpiration(2), 1);
        if (report !== undefined && report !== null) {
            reportManager.generateEmbedToken(props.currentUser, props.config.id).then((res) => {
                // Set AccessToken (Use the report object)
                    report.setAccessToken(res.embedToken).then(() => {
                            console.log('new token set');
                            //console.log("the raw res", res.expiration);
                            setTokenExpirationListener(res.expiration);
                        })
                        .catch((err) => console.error('Error setting token', err));

            });
        }
    };

    let reportManager = new ReportManager();

    return (<Report
        embedType="report"
        tokenType="Embed"
        accessToken={props.config.embedToken}
        embedUrl={props.config.embedUrl}
        embedId={props.config.id}
        permissions="All"
        reportMode="view"
        onLoad={onLoadAndSetTokenListener}
        onRender={onEmbedRender}
        style={
            {
                height: '90vh',
                width: '100%',
                display: 'block',
                top: '0',
                left: '0',
                position: 'absolute',
                border: '0',
                padding: '20px',
                background: '#fff',
                zIndex: '1'
            }
        }
    />)
}
export default React.memo(ReportComp);
