/* eslint-disable react/no-children-prop */
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import Reports from './components/Reports';
import logo from './images/logo.jpg';
import 'App.css';
import Dashboard from './components/Dashboard';
import Interventions from './components/Interventions';
import Documents from './components/Documents';
import UserModel from './models/userModel';

import Contacts from './components/interventions/Contacts';
import ContactsForm from './components/interventions/ContactsForm';
import { FaChartBar,FaExclamationTriangle, FaBolt,FaUsers,FaFileUpload, FaLock, FaLifeRing } from 'react-icons/fa';
import Uploads from './components/interventions/Uploads';
import SinglePageSurvey from 'components/interventions/SinglePageSurvey';
import { Helmet } from 'react-helmet'

const App: React.FC = () => {
  return (
    <Router>
      <Helmet><title>Stay Metrics Client Portal</title></Helmet>
      <Navbar expand="lg">
        <Navbar.Brand href="#home">
          <img className="logo" alt={'logo'} src={logo}></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
          <Nav.Link href="/reports">
            <div className="menu_icon">
                <div><FaChartBar className="icon-slide" color="#003267" size="20" /></div>
                <span className="report-icon-text"><strong>Reports</strong></span>
            </div>
            </Nav.Link>
            <Nav.Link href="/interventions">  
            <div className="menu_icon">
              <div><FaExclamationTriangle className="icon-slide" color="#003267" size="20" /></div>
              <span className="report-icon-text"><strong>Interventions</strong></span>
            </div>     
            </Nav.Link>
            <Nav.Link href="/contacts">
            <div className="menu_icon">
                <div><FaUsers className="icon-slide" color="#003267" size="20" /></div>
                <span className="report-icon-text"><strong>Contacts</strong></span>
            </div>
            </Nav.Link> 
            <Nav.Link href="/uploads">  
            <div className="menu_icon">
              <div><FaFileUpload className="icon-slide" color="#003267" size="20" /></div>
              <span className="report-icon-text"><strong>Uploads</strong></span>
            </div>     
            </Nav.Link>        
            <Nav.Link href="/documents">
            <div className="menu_icon">
                <div><FaLifeRing className="icon-slide" color="#003267" size="20" /></div>
                <span className="report-icon-text"><strong>Resources</strong></span>
            </div>
            </Nav.Link>
            <Nav.Link href="/logout">  
            <div className="menu_icon">
                <div><FaLock className="icon-slide" color="#003267" size="20" /></div>
                <span className="report-icon-text"><strong>Logout</strong></span>
            </div> 
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div className="content">
        <Switch>
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/reports/:report_id" component={Reports} />
          <PrivateRoute exact path="/reports" component={Reports} />
          <PrivateRoute path="/interventions" component={Interventions} />
          <PrivateRoute exact path="/contacts" component={Contacts} />
          <PrivateRoute path="/contacts/new" component={ContactsForm} />
          <PrivateRoute path="/contacts/edit/:contact_id" component={ContactsForm} />
          <PrivateRoute path="/uploads" component={Uploads} />
          <PrivateRoute path="/documents" component={Documents} />
          <PrivateRoute path="/survey/:participantId/:surveyTypeId/:surveyDate/:participantName" component={SinglePageSurvey} />
        </Switch>
      </div>
    </Router>
  );
};

// @ts-ignore
const PrivateRoute = ({ component: Component, ...rest }) => {
  
  if(!UserModel.isAuthorized() ) {
    UserModel.removeStorageUser();
    window.location.href = '/';
  }
  
  return (
    UserModel.isAuthorized() 
    ? <Route {...rest} render={(props) => (
      <Component {...props} currentuser={new UserModel()} />
    )} />
    : <Redirect to='/' />
  );
};

export default App;
