import React, {useEffect, useRef, useState, useCallback} from 'react';
import ReportManager from '../managers/reportManager';
import Report from 'powerbi-report-component';
import {FaChartLine, FaChartBar, FaDesktop, FaPrint, FaMailBulk} from 'react-icons/fa';
import PreloadManager from '../managers/preloadManager';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import Preloader from '../components/Preloader';
import ReportComp from '../components/report/ReportComp';
import { Container, Row, Col, Modal, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Helmet } from 'react-helmet'

interface ReportConfig {
    id : string;
    name : string;
    webUrl : string;
    embedUrl : string; //Embed URL
    datasetId : string; //Report ID
    embedToken : string; //Embed Token
}

class ReportConfigReal implements ReportConfig {
    'id' : string;
    'name' : string;
    'webUrl' : string;
    'embedUrl' : string;
    'datasetId' : string;
    'embedToken' : string;
    'expiration' : string;
}

const Reports = (props : any) => {

    const [config,
        setConfig] = useState({
        id: "",
        name: "",
        webUrl: "",
        embedUrl: "",
        embedToken: "",
        expiration: ""
    });

    const [webURL,
        setWebURL] = useState("");
    const [currentUser,
        setCurrentUser] = useState(props.currentuser);
    const [reportList,
        setReportList] = useState(new Array());
    const [reportId,
        setReportId] = useState(props.match.params.report_id);
    const [loading,
        setLoading] = useState(false);
    const [reportsLoading,
        setReportsLoading] = useState(false);
    const [isPaneOpen,
        setIsPaneOpen] = useState(false);

    const selectedPage = useRef();

    let reportManager : ReportManager = new ReportManager();

    const handleFullScreen = () => {
        var elem = document.documentElement;
        elem.requestFullscreen();
    };
    const handlePrint = () => {
        window.print();
    };
    const handleReportChange = (e : any) => {
        window.location.href = '/reports/' + e;
    };

    const SelectReportModal = props => {
    return (
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" dialogClassName="selectReportModal" centered>
                <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Other Available Reports</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    <div className="menu_content">
                            <ul className="report_ul">
                                {props.reportList.map((report, index) => {
                                    return (
                                        <li className="report_li" key={index} onClick={() => handleReportChange(report.id)}>
                                            <FaChartBar className="icon-slide" color="#fff" size="20"/>
                                            <span>{report.name}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    };

    useEffect(() => {
        let promiseList : any = [];
        setLoading(true);
        setReportsLoading(true);
        if (currentUser && currentUser.allowedReports.length !== 0) {
            currentUser
                .allowedReports
                .forEach((report : string) => {
                    promiseList.push(reportManager.getReportInfo(currentUser, report).then((info : any) => {
                        return info;
                    }),);
                });
        }

        Promise
            .all(promiseList)
            .then(values => {
                setReportList(values);
            });

        if (currentUser && currentUser.allowedReports.length !== 0 && currentUser.authenticated) {
            //If reportId is not set from the URL, get the latest added report.
            if (reportId === undefined) {
                reportManager
                    .getReportInfo(currentUser, currentUser.allowedReports[0])
                    .then(data => {
                        setConfig(data);
                        setLoading(false);
                        setReportsLoading(false);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                reportManager
                    .getReportInfo(currentUser, reportId)
                    .then((info : any) => {
                        setConfig(info);
                        setLoading(false);
                        setReportsLoading(false);
                    });
            }
        }
    }, []);

    return (
        <LoadingOverlay active={loading} spinner={< Preloader />}>
        <Helmet><title>Stay Metrics - Reports</title></Helmet>
        <SelectReportModal reportList={reportList} show={isPaneOpen} onHide={() => setIsPaneOpen(false)} />
            <div>
                <div
                    className={reportsLoading !== true
                    ? 'slide-wrapper'
                    : 'col-sm-12 loading-delete'}>
                    <div id="slide-report">
                        <div className="menu_icon" onClick={e => setIsPaneOpen(true)}>
                            <div>
                                <FaChartLine color="#003267" size="30"/>
                            </div>
                            <span>Reports</span>
                        </div>
                        <div className="menu_content"></div>
                    </div>
                    <div id="slide-full-screen">
                        <div className="menu_icon" onClick={handleFullScreen}>
                            <div>
                                <FaDesktop color="#003267" size="30"/>
                            </div>
                            <span>Full Screen</span>
                        </div>
                    </div>
                    <div id="slide-print">
                        <div className="menu_icon" onClick={handlePrint}>
                            <div>
                                <FaPrint color="#003267" size="30"/>
                            </div>
                            <span>Print</span>
                        </div>
                    </div>
                </div>
                {/*<div className="alert alert-warning fade show" role="alert">*/}
                {/*    <strong>Notice!</strong> We are aware that there may be issues with loading reports. Refreshing the page often resolves the problem. We are working with Microsoft on a more permanent solution. Thank you for your patience.*/}
                {/*</div>*/}
                <div
                    key={reportId}
                    className={loading !== true
                    ? 'col-sm-12'
                    : 'col-sm-12 loading-delete'}>

                    <ReportComp config={config} currentUser={currentUser}/>
                </div>
            </div>
        </LoadingOverlay>
    );
};

export default Reports;
