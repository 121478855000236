import React, {useEffect,useState} from 'react';
import {Button} from 'react-bootstrap';
import logo from '../images/logo.jpg';
import UserModel from 'models/userModel';
const axios = require('axios');

const Login = (props: any) => {

  // Bypass the real login screen until we need this
  if(UserModel.isAuthorized()) {
    window.location.href = '/dashboard';
  } else {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + "/api/auth/azureb2c/start",
      headers: {
          "Content-Type": "application/json-patch+json",
          "accept": "text/plain"
      }
    })
    .then(function (token: any) {
        console.log(token.data);
        window.location.href = token.data;
    })
    .catch(function (error: object){
        console.log(error);
    });
  }

  return (<div>Please Wait</div>);

  const handleLogin = () => {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + "/api/auth/azureb2c/start",
      headers: {
          "Content-Type": "application/json-patch+json",
          "accept": "text/plain"
      }
    })
    .then(function (token: any) {
        window.location.href = token.data;
    })
    .catch(function (error: object){
        console.log(error);
    });
  };

  return (
    <div>
      <div className="center-login">
        <div className="col-sm-3">
          <div className="form-group group-centered">
            <img className="logo-large" alt={'microsoft_login'} src={logo}></img>
          </div>
          <div className="form-group group-centered">
            <Button 
              size="lg" 
              variant="primary"
              onClick={handleLogin}
            >
              Client Portal Access
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;